<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.upPitch') }}</h1>
        <p>{{ $t('creator.haveVideo') }}</p>
      </b-col>

      <b-col class="col-12 mt-1">
        <b-form-file 
          v-model="file" 
          accept=".mp4, .mov"
          browse-text="Buscar" 
          class="my-2" 
          type="file"
          @change="handleFileUpload"
          @input="hasChangeFiles"
          :placeholder="$t('creator.chooseVideo')"
        >
        </b-form-file>

        <b-col v-for="(preview, index) in previews" :key="index" class="col-12 p-0 py-2 border-bottom d-flex justify-content-between box-previews-draggable">
          <div class="d-flex align-items-center">
            <b-avatar :src="preview.preview" square class="avatar-preview-sample" icon="camera-video"></b-avatar>
            <div class="d-flex flex-column">
              <strong v-if="!preview.uuid">
                <span v-if="preview.name.length > 0">
                  {{ utils.shortText(5, preview.name) }} <span v-if="preview.name.split(' ').length > 6">...</span>
                </span>
                <span v-else class="text-muted">{{ $t('creator.noCaption') }}</span>
              </strong>
              <strong v-else class="text-muted">{{ $t('creator.upImage') }}</strong>
              <span class="text-muted" v-if="uploadPercentage !== null">Cargando: {{ uploadPercentage }}%</span>
              <span class="text-muted">{{ formatTime(videoDuration) }} </span>
              <span class="text-muted">{{ formatFileSize(fileSize) }}</span>
            </div>
          </div>
          <b-button variant="flat-secondary" @click="deleteFile()">
            <feather-icon icon="TrashIcon" size="20"></feather-icon>
          </b-button>
        </b-col>

        <b-col class="col-12 p-0 py-2 d-flex" v-if="ready_pitch && !file && pitch.video_pitch">
          <video :src="utils.getAssetUrl(pitch.video_pitch)" controls class="video-preview-iframe"></video>
        </b-col>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <b-button :disabled="validateNext() || uploaded_file" variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">
          <b-spinner class="spinner-uploading-file" v-if="uploaded_file"></b-spinner>
          {{is_editing ? $t('creator.savePreview') : $t('creator.butonNext')}}
        </b-button>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <p>{{$t('creator.pitchRequired')}}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormFile,
  BAvatar,
  BSpinner,
} from 'bootstrap-vue';
import { getThumbnails } from 'video-metadata-thumbnails';
import utils from '@/libs/utils';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import pitch_service from '@/services/pitch_service'

export default {
  name: 'uploadVideo',
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    pitch: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BFormFile,
    BAvatar,
    BSpinner,
  },
  data() {
    return {
      utils,
      ready_pitch: Object.keys(this.pitch).length > 0,
      file: null,
      previews: [],
      uploaded_file: false,
      is_editing: this.$route.params.is_editing,
      uploadPercentage: null,
      videoDuration: null,
      fileSize: null
    }
  },
  created() {
    this.$emit('is_editing', this.is_editing)
  },
  methods: {
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.round(seconds % 60);
      return `${minutes} min ${remainingSeconds} sec`;
    },
    formatFileSize(bytes) {
      const megabytes = bytes / (1024 * 1024);
      return `${megabytes.toFixed(2)} MB`;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      const video = document.createElement('video');
      
      reader.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const percent = (event.loaded / event.total) * 100;
          this.uploadPercentage = Math.round(percent);
        }
      });

      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        this.videoDuration = video.duration;
      };
      video.src = URL.createObjectURL(file);

      this.fileSize = file.size;

      reader.readAsDataURL(file);
    },

    validateNext() {
      if (!this.ready_pitch) return !this.file
      else return !this.pitch.video_pitch && !this.file
    },
    hasChangeFiles() {
      this.previews = []
      const urls_blobs = [];
      setTimeout(() => {
        const url_blob = URL.createObjectURL(this.file);
        this.previews.push({preview: 'is_video', name: this.file.name});
        const thumbnail = getThumbnails(url_blob, {
          quality: 0.6
        });
        thumbnail.then((value) => {
          const url_blob_thubnail = URL.createObjectURL(value[0].blob);
          this.previews[0].preview = url_blob_thubnail;
        });
        this.previews = this.previews.concat(urls_blobs);
      }, 200)
    },
    deleteFile() {
      this.file = null
      this.previews = []
    },
    save() {
      if (this.file) {
        this.uploaded_file = true;
        if (!this.ready_pitch) {
          const stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))

          const form_data = new FormData()
          form_data.append('video', this.file)
          form_data.append('type_pitch', stepper_info.type_pitch)

          pitch_service.applyCastingCall(this.casting_call.uuid, form_data).then(response => {
            this.uploaded_file = false;
            this.$emit('updatePitch', response)
            if (response.data && response.data.response.code === 'castingcall.prevApply') {
              localStorage.removeItem('stepperInfo')
              this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
              this.$router.push({name: 'my_work', params: {section: 'pitches'}})
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t('creator.pitch_applied_title'),
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: this.$t('creator.pitch_applied_text')
                }
              });
            } else {
              this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'add_description', pitch_uuid: response.uuid}})
            }
          })
        } else {
          const form_data = new FormData()
          form_data.append('video', this.file)

          pitch_service.updateApplyCastingCall(this.$route.params.pitch_uuid, form_data).then(response => {
            this.uploaded_file = false;
            localStorage.removeItem('stepperInfo')
            this.$emit('updatePitch', response)
            if (this.is_editing) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
            else this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'add_description', pitch_uuid: response.uuid}})
          })
        }

      } else if (this.is_editing) {
        this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
      } else {  
        this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'add_description', pitch_uuid: this.$route.params.pitch_uuid}})
      }
    }
  },
}
</script>
<style scoped>
.avatar-preview-sample {
  margin-right: 1em;
  width: 45px;
  height: 45px;
  border-radius: 1em !important;
}
.spinner-uploading-file {
  height: 20px;
  width: 20px;
  margin-right: 0.5em;
}
.video-preview-iframe {
  width: 100%;
  border-radius: 1em;
  height: 400px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
</style>