<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1>{{$t('creator.choose_your')}}</h1>
        <p>{{ $t('creator.contentSocial') }}</p>
      </b-col>

      <b-col class="col-12 text-center mt-2  mb-1">
        <b-form-checkbox-group
          id="selected_type"
          v-model="selected_networks"
          name="selected_type"
          aria-label="Individual types"
          stacked
        >
          <b-col 
            class="col-12 mt-1 mx-auto"
            :class="checkActiveNetwork(network)"
            v-for="(network, index) in connections" 
            :key="index"
            ref="card_radio_button"
          >
            <div class="card-radion-button-steps-networks d-flex justify-content-between align-items-center" @click="toggleNetwork(network)">
              <div class="d-flex align-items-center">
                <b-avatar v-if="network.network !== 'twitter'" :src="utils.getImageNetworkColor(network.network)" class="bg-white mr-1"></b-avatar>
                <b-avatar v-else class="icon-content-twitter-conCasting mr-1">
                  <b-img :src="utils.getImageNetworkColor(network.network)" fluid class="icon-network-twitter-conCasting" />
                </b-avatar>
                <div class="text-left">
                  <strong>{{utils.capitalize(network.network)}}</strong>
                  <span class="text-muted d-block mt-1"> {{getUrlNetwork(network)}}</span>
                </div>
              </div>
              <b-form-checkbox :ref="`network-${network.uuid}`" :value="network.uuid" class="float-right"></b-form-checkbox>
            </div>
          </b-col>
        </b-form-checkbox-group>
      </b-col>

      <b-col class="col-12 text-center mb-3">
        <b-button @click="openSiderbar()" variant="btn-add-connection" class="my-2 btn-add-connection">{{$t('creator.text_button_connection')}} </b-button>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <b-button :disabled="selected_networks.length === 0" variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{is_editing ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
      </b-col>
      <b-col class="col-12 d-flex justify-content-center">
        <p>{{ $t('creator.required') }}</p>
      </b-col>
    </b-row>

    <sider-network 
      class="open-sidebar-search"
      @close_siderbar="closeSiderNetwork"
      @updated_network="getNetworks"
      :show_sider_network="show_sider_network"
      :key="change_sider"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
} from 'bootstrap-vue';
import siderNetwork from '../../proposals/stepper/siderNetwork.vue';
import service from "@/services/others";
import utils from '@/libs/utils';

import pitch_service from '@/services/pitch_service'

export default {
  name: 'connectionsCastinCall',
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    pitch: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BAvatar,
    BFormCheckboxGroup,
    BFormCheckbox,
    siderNetwork,
    BImg,
  },
  data() {
    return {
      utils,
      connections: [],
      show_sider_network: false,
      change_sider: false,
      selected_networks: [],
      is_editing: this.$route.params.is_editing,
    }
  },
  created() {
    this.getNetworks();
    this.$emit('is_editing', this.is_editing)
  },
  methods: {
    getNetworks() {
      let networks = ''

      if (this.casting_call.content_type === 'influencer_marketing') {
        this.casting_call.work_types.forEach((work_type, index) => {
          if (work_type === 'instagram_reels') networks += 'networks=instagram'
          else if (work_type === 'podcast') networks += 'networks=spotify'
          else networks += `networks=${work_type}`

          if ((index + 1) < this.casting_call.work_types.length) networks += '&'
        });
      }

      service.getSocialNetworks(networks).then((response) => {
        this.connections = response.results;
        this.prevNetworks()
      })
    },
    prevNetworks() {
      if (this.pitch.user_networks.length > 0) {
        this.pitch.user_networks.forEach(user_network => {
          this.selected_networks.push(user_network.uuid)
        });
      } else {
        this.connections.forEach(network => {
          this.selected_networks.push(network.uuid)
        });
      }
    },
    toggleNetwork(network) {
      this.$refs[`network-${network.uuid}`][0].$refs['input'].click()
    },
    checkActiveNetwork(network) {
      const select_network = this.selected_networks.find(net => net === network.uuid)
      if (select_network)
        return 'active-class-button'
      
      return ''
    },
    openSiderbar() {
      this.show_sider_network = true;
      this.change_sider = !this.change_sider
    },
    closeSiderNetwork() {
      this.show_sider_network = false;
      this.change_sider = !this.change_sider
    },
    save() {
      let stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))
      if (stepper_info) {
        stepper_info.selected_networks = this.selected_networks
      } else {
        stepper_info = {
          'selected_networks': this.selected_networks
        }
      }
      localStorage.setItem('stepperInfo', JSON.stringify(stepper_info))

      const data = {
        'user_networks_uuid': this.selected_networks
      }

      pitch_service.adduserNetworksCastingCall(this.$route.params.pitch_uuid, data).then(response => {
        if (this.is_editing) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
        else this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'contact', pitch_uuid: response.uuid}})
        this.$emit('updatePitch', response)
      })
    },
    getUrlNetwork(net) {
      return utils.getURL(net.network, net.username, (net.network === 'youtube'))
    },
    getWorkTypes() {
      const work_types = this.casting_call.work_types.map((work_type) => {
        return this.$t('creator.service_focus_in_influencer_marketing').options.find((item) => item.value === work_type).text
      });
      const sentence = work_types.join(', ');
      return sentence;
    }
  },
}
</script>
<style>
.card-radion-button-steps-networks {
  border-radius: 1em;
  background-color: white;
  overflow: hidden !important;
  padding: 1em;
  border: 1px solid rgba(210, 210, 210, 0.7); 
  position: relative;
}
.card-radion-button-steps-networks:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  cursor: pointer;
  transition: 500ms;
}
.active-class-button > .card-radion-button-steps-networks {
  background-color: #7267f01b !important;
  border: 1px solid #7367f0;
}
</style>
<style scoped>
.btn-add-connection {
  color: #55a6c4 !important;
  background-color: #EEF6F9 !important;
  border-color: rgba(0,0,0,0) !important;
}
.btn-add-connection:hover {
  background-color: #55A6C4 !important;
  color: #F6FBFC !important;
}
.icon-network-twitter-conCasting {
  width: 65%;
  height: 65%;
}
.icon-content-twitter-conCasting {
  display: flex;
  background: black;
  justify-content: center;
  align-items: center;
}
</style>