<template>
    <div class="d-flex justify-content-center">
      <b-row class="mt-2 col-12 col-lg-8 col-xl-5">

        <b-col class="col-12 mt-1">
          <div v-for="(question, index) in questionsFiltered()" :key="index">
            <build-question :question="question" :key="question.uuid" @answer_updated="answerUpdated"/>
          </div>
          <b-pagination
            pills
            v-model="current_page"
            :total-rows="questions.length"
            :per-page="per_page"
            next-class="d-none"
            prev-class="d-none"
            hide-goto-end-buttons
            class="justify-content-center mt-2"
            v-if="questions.length > 1"
          ></b-pagination>
        </b-col>

        <b-col class="col-12 d-flex justify-content-center">
          <b-button :disabled="!validateAnswers()" variant="primary" class="my-2" @click="save()">{{is_editing ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
        </b-col>
      </b-row>
    </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BPagination,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import buildQuestion from './buildQuestion.vue'
import pitch_service from '@/services/pitch_service'

import utils from '../../../../libs/utils';

export default {
  name: 'questionsCastingCall',
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    pitch: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BPagination,
    buildQuestion
  },
  data() {
    return {
      utils,
      questions: [],
      per_page: 1,
      current_page: 1,
      ready_answers_text: false,
      ready_answers_media: false,
      ready_pitch: Object.keys(this.pitch).length > 0,
      is_editing: this.$route.params.is_editing,
    }
  },
  created() {
    this.formatQuestions()
    this.$emit('is_editing', this.is_editing);
  },
  methods: {
    validateAnswers() {
      let valid_answers = true
      this.questions.forEach(question => {
        if (question.required_question) {
          if (question.type_question === 'media') {
            if (!this.ready_pitch) valid_answers = false
          }

          if (question.type_question === 'radio' && question.answer.length === 0) valid_answers = false
          if (question.type_question === 'checkbox' && question.answers_checkboxes.length === 0) valid_answers = false
          if (question.type_question === 'free_text' && question.answer.length === 0) valid_answers = false
        }
      });

      return valid_answers
    },
    answerUpdated(data) {
      const question = this.questions.find(que => que.uuid === data.question_uuid)
      if (question) {
        question.answer = data.answer
        question.answers_checkboxes = data.answers_checkboxes
        question.file = data.file
        question.previews = data.previews
      }
    },
    formatQuestions() {
      const questions = []
      this.casting_call.questions.forEach(question => {
        const prev_answer = this.pitch.answers.find(ans => ans.question.uuid === question.uuid)
        let answer = ''
        let answers_checkboxes = []
        let has_file = false
        let media = ''

        if (prev_answer) {
          if (question.type_question === 'checkbox') {
            answers_checkboxes = prev_answer.answer
          } else if (question.type_question === 'media') {
            has_file = true
            media = utils.getAssetUrl(prev_answer.media)
          } else {
            answer = prev_answer.answer
          }
        }

        const obj = {
          ...question,
          answer,
          answers_checkboxes,
          has_file,
          file: null,
          previews: [],
          media
        }

        questions.push(obj)
      });

      questions.sort(que => {
        return que.required_question
      })

      this.questions = questions
    },
    questionsFiltered () {
      const questions = this.questions;
      return questions.slice((this.current_page - 1) * this.per_page, this.current_page * this.per_page);
    },
    save() {
      const valid_answers = this.validateAnswers()

      if (!valid_answers) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Formulario incompleto',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: 'Hay algunas respuestas que son obligatorias y no se han respondido'
          }
        })

        return
      }

      const answers_json = []
      const answers_files = new FormData();
      const relation_file_answer = []

      this.questions.forEach(question => {
        if (question.type_question === 'media' && question.file) {
          answers_files.append('files', question.file)
          const file_question = {
            'question_uuid': question.uuid,
            'name': question.file.name
          }
          relation_file_answer.push(file_question)
        } else if (question.type_question === 'checkbox' && question.answers_checkboxes.length > 0) {
          const answer = {
            'question_uuid': question.uuid,
            'answer': JSON.stringify(question.answers_checkboxes)
          }
          answers_json.push(answer)
        } else if (question.answer.length > 0) {
          const answer = {
            'question_uuid': question.uuid,
            'answer': question.answer
          }
          answers_json.push(answer)
        }
      });
      if (answers_json.length > 0) {
        pitch_service.responseQuestions(this.$route.params.pitch_uuid, answers_json).then(response => {
          this.$emit('updatePitch', response)
          if (this.is_editing) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
          this.ready_answers_text = true
        })
      } else {
        this.ready_answers_text = true
      }

      if (relation_file_answer.length > 0) {
        answers_files.append('relation_file_answer', JSON.stringify(relation_file_answer))
        pitch_service.uploadMediaQuestions(this.$route.params.pitch_uuid, answers_files).then(response => {
          this.ready_answers_media = true
          this.$emit('updatePitch', response)
        })
      } else {
        this.ready_answers_media = true
      }
    }
  },
  watch: {
    ready_answers_text(newVal) {
      if ((newVal && this.ready_answers_media) && !this.is_editing) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'desired_payment', pitch_uuid: this.$route.params.pitch_uuid}})
    },
    ready_answers_media(newVal) {
      if ((this.ready_answers_text && newVal) && !this.is_editing) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'desired_payment', pitch_uuid: this.$route.params.pitch_uuid}})
    }
  }
}
</script>