<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5 justify-content-center">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.record_your_pitch') }}</h1>
        <p>{{ $t('creator.record_text') }}</p>
      </b-col>

      <b-col class="col-12 p-0 py-2 d-flex justify-content-center" v-if="!is_starting_camera">
        <div class="position-relative height-width-video">
          <video :src="utils.getAssetUrl(pitch.video_pitch)" controls class="video-asset"></video>
            <div class="container-record-again" @click="is_starting_camera = true">
            <b-icon icon="circle-fill" class="icon-record-action mr-1 text-danger"></b-icon>
            {{$t('creator.record_again')}}
          </div>
        </div>  
      </b-col>
      <div v-else class="">
        <div class="mt-1 w-100 d-flex justify-content-center">
          <div class="position-relative height-width-video w-100">
            <video ref="video" autoplay class="video-asset d-block" :src="video_src" :controls="video_file"></video>
            <div class="container-icons-camera" @click="startCamera()" v-if="!camera_stream">
              <b-icon icon="camera-fill" class="icon-camera"></b-icon>
              <div class="">
                <b-icon icon="mic-fill" class="icon-mic"></b-icon>
              </div>
            </div>
            <div class="container-in-record" v-if="is_recording">
              <span class="rec-text-animation">Rec</span> 
              <b-icon icon="circle-fill" class="recording-icon"></b-icon>
            </div>
            <div class="container-record-again" @click="handleRecordAction()" v-if="video_file">
              <b-icon icon="circle-fill" class="icon-record-action mr-1 text-danger"></b-icon>
              {{$t('creator.record_again')}}
            </div>
            <div class="container-controls-video text-white" v-if="camera_stream && !video_file">
              <div class="d-flex align-items-center">
                <b-avatar :class="`${is_recording ? 'border-avatar-record' : 'border-avatar'} mr-1 cursor-pointer`">
                  <b-icon :icon="is_recording ? 'square-fill' : 'circle-fill'" :font-scale="is_recording ? '0.7' : '1.2'" class="text-danger" @click="handleRecordAction()"></b-icon>
                </b-avatar>
                <span class="d-block time-lapsed-text">{{formatTime(time_lapsed)}} / 03:00</span>
              </div>
              <b-icon icon="arrows-fullscreen" class="cursor-pointer" @click="fullScreenAction()"></b-icon>
            </div>
          </div>
        </div>
        <b-col class="mt-1 col-12" v-if="!camera_stream">
          <b-alert show class="p-1 alert-camera" variant="alert-camera">
            <div class="d-flex align-items-center">
              <feather-icon icon="AlertTriangleIcon" class="mr-1" size="20"></feather-icon>
              <strong>{{$t('creator.permission_required')}}</strong>
            </div>
            <span class="avenir-medium mt-05 d-block">{{$t('creator.click_micro')}}</span>
          </b-alert>
        </b-col>
        <b-col class="mt-1 col-12" v-if="alert_no_more_recording">
          <b-alert show variant="danger" class="p-1" dismissible>
            <div class="d-flex align-items-center">
              <feather-icon icon="AlertTriangleIcon" class="mr-1" size="20"></feather-icon>
              <strong>{{$t('creator.limit_reached')}}</strong>
            </div>
            <span class="avenir-medium mt-05 d-block">{{$t('creator.more_3_minutes')}}</span>
          </b-alert>
        </b-col>
        <b-col 
          class="col-12 p-0 py-2 border-bottom d-flex justify-content-between box-previews-draggable"
          v-if="Object.keys(preview).length > 0"
        >
          <div class="d-flex align-items-center">
            <video :src="preview.preview" square class="avatar-preview-sample" icon="camera-video"></video>
            <strong v-if="!preview.uuid">
              <span>
                {{$t('creator.video_recorded')}}
              </span>
              <span class="text-muted d-block">{{$t('creator.duration')}}: {{formatTime(total_duration_video_loaded)}}</span>
              <span class="text-muted d-block">{{$t('creator.size')}}: {{ video_size.toFixed(2) }} MB </span>
            </strong>
            <strong v-else class="text-muted">{{ $t('creator.upImage') }}</strong>
          </div>
          <b-button variant="flat-secondary" @click="deleteFile()">
            <feather-icon icon="TrashIcon" size="20"></feather-icon>
          </b-button>
        </b-col>
      </div>
      <b-col class="col-12 d-flex justify-content-center">
        <b-button :disabled="!video_file && !pitch.video_pitch" variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{is_editing ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BAlert,
  BCol,
  BAvatar,
} from 'bootstrap-vue';
import pitch_service from '@/services/pitch_service';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import utils from '@/libs/utils';

export default {
  name: 'videoRecorder',
  components: {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BAlert,
  },
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    pitch: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    if (!this.ready_pitch || !this.pitch.video_pitch) this.is_starting_camera = true;
    else this.is_starting_camera = false;
  },
  data() {
    return {
      utils,
      is_starting_camera: false,
      camera_stream: null,
      media_recorder: null,
      blobs_recordered: [],
      is_recording: false,
      preview: {},
      video_src: null,
      ready_pitch: Object.keys(this.pitch).length > 0,
      video_file: null,
      time_lapsed: 0,
      interval_time: null,
      alert_no_more_recording: false,
      total_duration_video_loaded: 0,
      is_editing: this.$route.params.is_editing,
      videoSizeInBytes: 0,
    };
  },
  destroyed() {
    this.stopCamera();
  },
  methods: {
    save() {
      if (this.video_file) {
        if (!this.ready_pitch) {
          const stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))
          const form_data = new FormData()
          form_data.append('video', this.video_file)
          form_data.append('type_pitch', stepper_info.type_pitch)
          pitch_service.applyCastingCall(this.casting_call.uuid, form_data).then(response => {
            this.$emit('updatePitch', response)
            if (response.data && response.data.response.code === 'castingcall.prevApply') {
              localStorage.removeItem('stepperInfo')
              this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
              this.$router.push({name: 'my_work', params: {section: 'pitches'}})
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t('creator.pitch_applied_title'),
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: this.$t('creator.pitch_applied_text')
                }
              });
            } else {
              this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'add_description', pitch_uuid: response.uuid}})
            }
          })
        } else {
          const form_data = new FormData()
          form_data.append('video', this.video_file)
          pitch_service.updateApplyCastingCall(this.$route.params.pitch_uuid, form_data).then(response => {
            this.uploaded_file = false;
            localStorage.removeItem('stepperInfo')
            this.$emit('updatePitch', response)
            if (this.is_editing) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
            else this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'add_description', pitch_uuid: response.uuid}})
          })
        }
      } else if (this.is_editing) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
    },
    deleteFile() {
      this.preview = {}
      this.video_file = null;
      this.video_src = null;
      this.$refs.video.srcObject = this.camera_stream;
    },
    handleRecordAction() {
      this.is_recording = !this.is_recording;
      this.is_recording ? this.startRecording() : this.stopRecording();
    },
    stopCamera() {
      if (this.camera_stream) {
        const tracks = this.camera_stream.getTracks();
        tracks.forEach(track => track.stop());
        this.camera_stream = null;
        clearInterval(this.interval_time)
      }
    },
    async startCamera() {
      try {
        this.camera_stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true,
        });
        this.$refs.video.srcObject = this.camera_stream;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t('creator.error_camera'),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t('creator.no_camera_found')
          }
        });
      }
    },
    startRecording() {
      this.video_src = null;
      this.video_file = null;
      this.$refs.video.srcObject = this.camera_stream;
      this.blobs_recordered = [];
      this.media_recorder = new MediaRecorder(this.camera_stream, {mimeType: "video/webm",});
      this.media_recorder.addEventListener("dataavailable", (e) => {
        if (e.data.size > 0) {
          this.blobs_recordered.push(e.data);
        }
      });

      this.media_recorder.addEventListener("stop", () => {
        this.media_recorder = null;
      });

      this.media_recorder.start(1000);
      this.interval_time = setInterval(() => {
        this.time_lapsed += 1;
      }, 1000)
    },
    stopRecording() {
      this.media_recorder.stop();
      this.is_recording = false;
      const video_blob = new Blob(this.blobs_recordered, {type: "video/webm"});
      this.video_file = new File([video_blob], `${utils.getUniqueIndex()}.mp4`, { type: video_blob.type });
      this.preview = {preview: URL.createObjectURL(this.video_file)};
      this.$refs.video.srcObject = null;
      this.video_src = this.preview.preview;
      this.total_duration_video_loaded = this.time_lapsed;
      this.time_lapsed = 0;
      clearInterval(this.interval_time)
      this.video_size = this.video_file.size / (1024 * 1024);
    },
    fullScreenAction() {
      this.$refs.video.requestFullscreen();
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remaining_secondes = seconds % 60;
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remaining_secondes).padStart(2, '0');
      return `${formattedMinutes}:${formattedSeconds}`;
    },
  },
  watch: {
    time_lapsed() {
      if (this.time_lapsed === 180 && this.is_recording) {
        this.alert_no_more_recording = true;
        this.stopRecording();
      }
    }
  }
};
</script>
<style scoped>
.border-avatar {
  border: 1px solid rgb(206, 212, 218) !important;
  background-color: transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.border-avatar-record {
  border: 1px solid rgb(206, 212, 218) !important;
  background-color: transparent;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.alert-camera {
  color: #3b8dab;
  background-color: #e6f2f6;
}
.recording-icon {
  color: red;
  margin-left: 0.5em;
}

.container-in-record {
  position: absolute;
  right: 0.6em;
  top: 0.6em;
}
.height-width-video {
  width: 520px;
  height: 300px;
}
.video-asset {
  background-color: black;
  width: 520px;
  height: 300px;
}
.container-icons-camera, .icon-camera, .icon-mic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.container-icons-camera {
  color: white;
  z-index: 100;
  width: 75px;
  background-color: transparent;
  height: 75px;
}
.container-icons-camera:hover {
  cursor: pointer;
}
.container-icons-camera:hover .icon-camera {
  box-shadow: rgba(251, 251, 251, 0.56) 0px 22px 70px 4px;
}
.icon-camera {
  width: 60px;
  height: 60px;
  background-color: transparent ;
  padding: 0px;
}
.icon-mic {
  color: black;
  width: 30px;
  height: 30px;
  background-color: white;
}
.mt-05 {
  margin-top: 0.5em;
}
.icon-record-action {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.icon-record-action:hover {
  box-shadow: rgb(255, 255, 255) 0px 7px 29px 0px;
}
.container-record-again {
  position: absolute;
  left: 0.6em;
  top: 0.6em;
  background-color: rgba(0,0,0,0.5);
  padding: 0.4em;
  color: white;
  border-radius: 0.5em;
  cursor: pointer;
}
.container-record-again:hover {
  background-color: rgba(97, 97, 97, 0.5);
  transition: all 200ms;
}
.container-controls-video {
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;  
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5em;
}
.time-lapsed-text {
  font-family: 'avenir-medium';
  font-size: 12px;
}
.avatar-preview-sample {
  margin-right: 1em;
  width: 45px !important;
  height: 45px !important;
  object-fit: cover !important;
  border-radius: 1em !important;
}
/* @media(max-width: 525px) {
  .video-asset {
    width: 100%;
  }
} */
</style>
<style>
.rec-text-animation {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  animation: text-rec 2s infinite;
  transition: all 500ms;
}
@keyframes text-rec {
  0% {
    color: rgba(255, 255, 255, 0.56);
  }
  50% {
    color: white;
  }
  100% {
    color: rgba(255, 255, 255, 0.56);
  }
}
</style>